import { BASE_URL } from "../configs";
import { axiosInstanceVisit } from "./config";

const URL = BASE_URL + "/comment";

export const addComment = (data) => {
  return axiosInstanceVisit.post(`${URL}`, data);
};

export const getCommentByArticle = (id) => {
  return axiosInstanceVisit.get(`${URL}/${id}`);
};

export const updateCommentById = (data) => {
  const form = { content_comment: data.content_comment };
  return axiosInstanceVisit.put(`${URL}/${data.idSelectUpdate}`, form);
};

export const deleteCommentById = (id) => {
  return axiosInstanceVisit.delete(`${URL}/${id}`);
};

export const setCommentSeen = (id) => {
  return axiosInstanceVisit.put(`${BASE_URL}/commentSeen/${id}`);
};
