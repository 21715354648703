<template>
  <div class="reponse mt-2 ps-3">
    <div class="bar rounded"></div>
    <!-- reponse -->
    <div
      v-for="response in data"
      :key="response.id_rep"
      class="bg-secondary text-light p-3 rounded mb-3"
    >
      <div>
        <span class="text-primary fw-bold"> {{ response.fname_collab }}</span>
        {{ response.content_rep }}
      </div>
      <div
        class="text-muted mt-2 d-flex justify-content-between align-items-center"
      >
        <small>
          {{ moment(new Date(response.date_rep), "YYYYMMDD").fromNow(true) }}
        </small>
        <!-- manage reponse admin -->
        <!-- v-if="checkIfMine(comment.id_visiteur)" -->
        <div
          class="d-flex btn-actions"
          role="button"
          tabindex="0"
          v-if="checkIfMine(response.id_collab)"
        >
          <div
            class="btn-actions-sub d-flex justify-content-evenly align-items-center p-2 px-3 rounded-pill"
          >
            <small
              data-bs-toggle="modal"
              data-bs-target="#modalModifierReponse"
              class="mx-1"
              @click="updateReponse(response.id_rep, response.content_rep)"
              >Modifier</small
            >
            <small
              data-bs-toggle="modal"
              data-bs-target="#modalDeleteReponse"
              @click="deleteRep(response.id_rep)"
              class="mx-1"
              >Supprimer</small
            >
          </div>
          <small
            class="btn-dots d-flex justify-content-center align-items-center px-2 ms-2 rounded-pill"
            ><IcoAction
          /></small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReponseByComment } from "../../api/reponse";
import { socket } from "../../api/socket";
import IcoAction from "vue-material-design-icons/DotsHorizontal.vue";
import { checkIfMine } from "../../utils/index";

export default {
  name: "ListReponseAdmin",
  props: ["id_comment"],
  components: { IcoAction },
  data() {
    return {
      data: [],
      formUpdate: { idSelectUpdate: 0, content_rep: "" },
      idDeleteRep: 0,
      loading: {
        comments: true,
        post: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.fetch();
    socket.on("arrival-comment", () => {
      this.fetch();
    });
  },
  methods: {
    fetch() {
      getReponseByComment(this.id_comment).then((result) => {
        this.data = result.data;
      });
    },
    updateReponse(id, reponse) {
      this.$emit("updateReponse", {
        id: id,
        reponse: reponse,
      });
    },
    deleteRep(id) {
      this.$emit("initDeleteRep", id);
    },
    checkIfMine(id) {
      return checkIfMine(id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.reponse {
  position: relative;

  .bar {
    position: absolute;
    width: 0.15rem;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $secondary;
    opacity: 0.5;
  }
}

.btn-actions {
  cursor: default;
  transition: 250ms;

  .btn-actions-sub {
    visibility: hidden;
    background-color: $primary;
    opacity: 0;
    transition: 250ms;

    small {
      transition: 250ms;
      cursor: pointer;

      &:hover {
        color: $light;
      }
    }
  }

  .btn-dots {
    cursor: pointer;
    transition: 250ms;

    &:hover {
      background-color: $primary;
      transform: scale(1.1);
    }
  }

  &:focus .btn-actions-sub {
    opacity: 1;
    visibility: visible;
  }
}
</style>
