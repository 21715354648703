<template>
  <section class="bg-light h-100 p-3">
    <h2>Commentaires {{ data.length }}</h2>
    <hr />
    <div v-for="comment in data" :key="comment.id_comment">
      <div class="d-flex">
        <div
          class="bg-secondary text-light rounded mb-3 p-3 cursor-pointer col-12"
          :class="{
            'border border-primary border-2': comment.id_comment == idComment,
          }"
          @click="selectComment(comment.id_comment, comment.content_comment)"
        >
          <div>
            <span class="text-primary fw-bold">
              {{ `${comment.first_name_visit} ${comment.last_name_visit}` }}
            </span>
            {{ comment.content_comment }}
          </div>
          <div
            class="text-muted mt-2 d-flex justify-content-between align-items-center"
          >
            <small>
              {{
                moment(new Date(comment.date_comment), "YYYYMMDD").fromNow(true)
              }}
            </small>
            <div class="d-flex btn-actions" role="button" tabindex="0">
              <div
                class="btn-actions-sub d-flex justify-content-evenly align-items-center p-2 px-3 rounded-pill"
              >
                <small
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  @click="initDeleteComment(comment.id_comment)"
                  class="mx-1"
                  >Supprimer</small
                >
              </div>
              <small
                class="btn-dots d-flex justify-content-center align-items-center px-2 ms-2 rounded-pill"
                ><IcoAction
              /></small>
            </div>
          </div>
        </div>
      </div>
      <Reponse
        @updateReponse="updateReponse"
        @initDeleteRep="initDeleteRep"
        :id_comment="comment.id_comment"
      />
    </div>
    <h4 class="text-primary fw-bold">
      Répondre
      <span v-if="commentSelected.length"> à {{ commentSelected }}</span>
    </h4>
    <form @submit.prevent="reply">
      <div class="col-12 mt-3">
        <!-- <label for="inputMessage" class="form-label">Votre commentaire</label> -->
        <textarea
          class="form-control shadow"
          required
          rows="5"
          v-model="content_rep"
          style="resize: none"
          @focus="viewCommentaire"
          placeholder="Entrez votre commentaire ici. Merci de soigner votre orthographe pour une lecture plus confortable."
        ></textarea>
      </div>

      <div class="col-12 mb-2 mt-4">
        <button
          v-if="loading.post"
          class="btn btn-primary"
          type="button"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Envoi...
        </button>
        <button v-else type="submit" class="btn btn-primary">Envoyer</button>
      </div>
    </form>
    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Supprimer le commentaire
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">Le commentaire ne sera plus visible</div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <button
              v-if="loading.delete"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              @click="deleteComment()"
              class="btn btn-primary ms-3"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal rep update  début -->
    <div
      class="modal fade"
      id="modalModifierReponse"
      tabindex="0"
      aria-labelledby="modalLabelModifierReponse"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <form
          @submit.prevent="confirmUpdateRep"
          class="modal-content border-0 bg-light text-dark"
        >
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelModifierReponse">
              Modifier la réponse
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                <textarea
                  class="form-control"
                  id="inputMessage"
                  rows="4"
                  required
                  v-model="formUpdate.content_rep"
                  style="resize: none"
                ></textarea>
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseUpdate"
            >
              Annuler
            </button>
            <button
              v-if="loading.update"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button v-else type="submit" class="btn btn-primary ms-3">
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- modal rep update  fin -->

    <!-- modal rep delete début-->
    <div
      class="modal fade"
      id="modalDeleteReponse"
      tabindex="-1"
      aria-labelledby="modalLabelDeleteRep"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDeleteRep">
              Supprimer la réponse
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">La réponse sera supprimée</div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDeleteRep"
            >
              Annuler
            </button>
            <button
              v-if="loading.delete"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button v-else @click="deleteRep()" class="btn btn-primary ms-3">
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal rep delete fin-->
  </section>
</template>

<script>
import {
  getCommentByArticle,
  setCommentSeen,
  deleteCommentById,
} from "../../api/comment";
import {
  postResponse,
  updateResponseById,
  deleteResponseById,
} from "../../api/reponse";
import { error } from "../../utils/toast";
import IcoAction from "vue-material-design-icons/DotsHorizontal.vue";
import { socket } from "../../api/socket";
import Reponse from "../../components/admin/ReponseAdmin.vue";

export default {
  name: "CommentaireArticle",
  components: { IcoAction, Reponse },
  data() {
    return {
      data: [],
      idComment: null,
      commentSelected: "",
      content_rep: "",
      idDelete: 0,
      idDeleteRep: 0,
      loading: {
        comments: true,
        post: false,
        delete: false,
        update: false,
      },
      formUpdate: { idSelectUpdate: 0, content_rep: "" },
    };
  },
  mounted() {
    this.fetch();
    socket.on("arrival-comment", () => {
      this.fetch();
    });
  },
  methods: {
    fetch() {
      getCommentByArticle(this.$route.params.id).then((result) => {
        this.loading.comments = false;
        this.data = result.data;
        if (result.data.error) {
          this.$router.push(`/admin/liste-article`);
        }
      });
    },
    selectComment(id, com) {
      this.idComment = id;
      this.commentSelected = com;
    },
    initDeleteRep(id) {
      this.idDeleteRep = id;
    },
    updateReponse(data) {
      this.formUpdate = {
        idSelectUpdate: data.id,
        content_rep: data.reponse,
      };
    },
    finishUpdate() {
      this.fetch();
    },
    viewCommentaire() {
      if (this.idComment) {
        setCommentSeen(this.idComment).then(() => {
          socket.emit("send-view");
          this.fetch();
        });
      }
    },
    initDeleteComment(id) {
      this.idDelete = id;
    },
    reply() {
      if (this.idComment) {
        this.loading.post = true;
        postResponse({
          id_comment: this.idComment,
          content_rep: this.content_rep,
        }).then((result) => {
          socket.emit("change-comment", result.data);
          this.loading.post = false;
          this.fetch();
          this.idComment = null;
          this.content_rep = "";
          this.commentSelected = "";
        });
      } else {
        error("Veuillez selectionnez un commentaire");
      }
    },
    deleteComment() {
      this.loading.delete = true;
      deleteCommentById(this.idDelete).then(() => {
        socket.emit("change-comment");
        this.fetch();
        this.loading.delete = false;
        socket.emit("send-view");
        this.$refs.CloseDelete.click();
      });
    },
    deleteRep() {
      this.loading.delete = true;
      deleteResponseById(this.idDeleteRep).then(() => {
        this.fetch();
        socket.emit("change-comment");
        this.loading.delete = false;
        this.$refs.CloseDeleteRep.click();
      });
    },
    confirmUpdateRep() {
      this.loading.update = true;
      updateResponseById(this.formUpdate).then(() => {
        this.fetch();
        socket.emit("change-comment");
        this.loading.update = false;
        this.$refs.CloseUpdate.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.reponse {
  position: relative;

  .bar {
    position: absolute;
    width: 0.15rem;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $secondary;
    opacity: 0.5;
  }
}

.btn-actions {
  cursor: default;
  transition: 250ms;

  .btn-actions-sub {
    visibility: hidden;
    background-color: $primary;
    opacity: 0;
    transition: 250ms;

    small {
      transition: 250ms;
      cursor: pointer;

      &:hover {
        color: $light;
      }
    }
  }

  .btn-dots {
    cursor: pointer;
    transition: 250ms;

    &:hover {
      background-color: $primary;
      transform: scale(1.1);
    }
  }

  &:focus .btn-actions-sub {
    opacity: 1;
    visibility: visible;
  }
}
</style>
