import { BASE_URL } from "../configs";
import { axiosInstanceCollab, axiosInstanceVisit } from "./config";

const URL = BASE_URL + "/reponse";

export const getReponseByComment = (id) => {
  return axiosInstanceCollab.get(`${URL}/${id}`);
};

export const postResponse = (data) => {
  return axiosInstanceCollab.post(`${URL}`, data);
};

export const updateResponseById = (data) => {
  const form = { content_rep: data.content_rep };
  return axiosInstanceCollab.put(`${URL}/${data.idSelectUpdate}`, form);
};

export const deleteResponseById = (id) => {
  return axiosInstanceCollab.delete(`${URL}/${id}`);
};

export const setReponseView = (id) => {
  return axiosInstanceVisit.put(`${BASE_URL}/reponseRead/${id}`);
};
